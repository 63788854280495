import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import Loader from "../../shared/util/loader";
import { useMediaQuery } from "@mui/material";
import useExportDataLogic from "./export-data.logic";
import { downloadAndSavePDF } from "../../shared/util/download-pdf";
import { Link } from "react-router-dom";
import EnhancedTableHead from "../../shared/components/table-head-cell/enhanced-table-head";
import {
  exportDataItemDTO,
  exportDataMasterItemsDto,
  exportDataSearchCriteriaDto,
} from "../../models/export-data-dto";
import { sortAndUpdateTheData } from "../../shared/util/rate-estimate";
import ExportDataSearchCriteria from "../../shared/components/export-data/export-data-search-criteria";
import NoDataFound from "../../shared/components/NoDataFound";
import moment from "moment";
type Order = "desc" | "asc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof exportDataMasterItemsDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

const headCells: HeadCell[] = [
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "NAME",
    minwidth: "150",
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "DESCRIPTION",
    minwidth: "200",
  },
  {
    id: "exportSourceName",
    numeric: true,
    disablePadding: false,
    label: "SOURCE",
    minwidth: "150",
  },
  {
    id: "insertUserName",
    numeric: true,
    disablePadding: false,
    label: "CREATED BY",
    minwidth: "140",
  },
];

interface _Props {
  title: string;
}
const ExportData = ({ title }: _Props) => {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof exportDataMasterItemsDto>("name");
  const [notData, setNotData] = React.useState<any>("");

  const [isLoading, setIsLoading] = useState(false);
  const { getMasterItems, exportData, getMasterItemsById } =
    useExportDataLogic();

  const [exportMasterItemsData, setExportMasterItemsData] = useState<
    exportDataMasterItemsDto[]
  >([]);
  const [exportItemData, setExportItemData] = useState<
    exportDataItemDTO | undefined
  >(undefined);
  const token = useDecodeJWT();
  const [isVisibleSearchCriteria, setIsVisibleSearchCriteria] = useState(false);
  const maxSearchDays = localStorage.getItem("maxSearchDays");

  interface servicestyle {
    maxHeight: string;
  }

  const [servicestyle, setservicestyle] = useState<servicestyle>({
    maxHeight: "370px", // Default max height for desktop
  });

  const isMobile = useMediaQuery("(max-width:599px)");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof exportDataMasterItemsDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    const _data = sortAndUpdateTheData(exportMasterItemsData, order, orderBy);
    setExportMasterItemsData(_data);
  }, [order, orderBy]);

  useEffect(() => {
    getMasterItemsData();
  }, []);

  const handleExportData = async (
    searchFormState: exportDataSearchCriteriaDto
  ) => {
    try {
      setIsLoading(true);
      const {
        accountNumber,
        reference,
        pickupCountryID,
        deliveryCountryID,
        officeID,
        arriveDateTime_End,
        arriveDateTime_Start,
        pickUpStartDate,
        pickUpEndDate,
        deliveryStartDate,
        deliveryEndDate,
      } = searchFormState;

      const param = {
        ...searchFormState,
        accountNumber: accountNumber === "All" ? "" : accountNumber,
        officeID: officeID === "All" ? null : parseInt(officeID),
        pickupCountryID: pickupCountryID === "All" ? "" : pickupCountryID,
        deliveryCountryID: deliveryCountryID === "All" ? "" : deliveryCountryID,
        reference: reference,
        arriveDateTime_End: arriveDateTime_End
          ? moment(arriveDateTime_End).format("yyyy-MM-DD")
          : null,
        arriveDateTime_Start: arriveDateTime_Start
          ? moment(arriveDateTime_Start).format("yyyy-MM-DD")
          : null,
        maxSearchDays: maxSearchDays ? parseInt(maxSearchDays) : null,
        pickUpStartDate: pickUpStartDate
          ? moment(pickUpStartDate).format("yyyy-MM-DD")
          : null,
        pickUpEndDate: pickUpEndDate
          ? moment(pickUpEndDate).format("yyyy-MM-DD")
          : null,
        deliveryStartDate: deliveryStartDate
          ? moment(deliveryStartDate).format("yyyy-MM-DD")
          : null,
        deliveryEndDate: deliveryEndDate
          ? moment(deliveryEndDate).format("yyyy-MM-DD")
          : null,
      };

      const response = await exportData(param);

      if (response) {
        const Title = "gvExportData";
        const result = await downloadAndSavePDF(
          response,
          `${Title}.${exportItemData?.defaultFormat.toLowerCase() || "xls"}`
        );
        if (result.success) {
          setIsLoading(false);
        }
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  const getMasterItemsData = async () => {
    try {
      setIsLoading(true);
      const response = await getMasterItems();

      if (response.data && response.data.data) {
        const _data = sortAndUpdateTheData(response.data.data, order, orderBy);
        setExportMasterItemsData(_data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setExportMasterItemsData([]);
    }
  };

  const handleExportItem = async (exportMasterID: number) => {
    try {
      setIsLoading(true);
      const response = await getMasterItemsById(exportMasterID);

      if (response.data && response.data.data) {
        setIsVisibleSearchCriteria(true);
        setExportItemData(response.data.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setExportItemData(undefined);
    }
  };

  const toPascalCase = (str: any) => {
    return str
      .replace(
        /\w\S*/g,
        (word: any) =>
          word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
      )
      .replace(/\s+/g, "");
  };
  return (
    <>
      <div className={"active-order "}>
        <>
          {!isVisibleSearchCriteria && (
            <>
              <Box
                className="Header-main pt-16"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  className="Main-Header "
                  variant="h4"
                  color={"primary"}
                >
                  {title}
                </Typography>
              </Box>
              <Box
                className="Header-main pt-16 mb-24"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography className="other_dtl grey-color" variant="h6">
                  To run the Report item, click on the NAME of the report. You
                  will be prompted to enter parameters.
                </Typography>
              </Box>
            </>
          )}

          {/* search form */}
          {isVisibleSearchCriteria && (
            <ExportDataSearchCriteria
              handleExportData={handleExportData}
              returnToList={() => setIsVisibleSearchCriteria(false)}
              exportItemData={exportItemData as any}
            />
          )}

          {!isVisibleSearchCriteria && (
            <Box className="mt-8" sx={{ width: "100%" }}>
              <Paper className="ActiveOrder Batch-grid" sx={{ width: "100%" }}>
                <TableContainer className="exportdata-grid">
                  {" "}
                  {/* Add class name for styling */}
                  <Table aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={exportMasterItemsData.length}
                      headCells={headCells}
                    />
                    <TableBody>
                      {isLoading ? (
                        <TableRow>
                          <TableCell colSpan={12} align="center">
                            <Loader />
                          </TableCell>
                        </TableRow>
                      ) : exportMasterItemsData.length === 0 ? (
                        <TableRow className="Nodata_found" id="trExportDataNotFound">
                          <TableCell
                            className="No-data-section"
                            colSpan={12}
                            align="center"
                            id="tdDataNotFound"
                          >
                            <NoDataFound
                              heading="No data to display"
                              title={
                                "Please ensure you have report items before exporting."
                              }
                              isDisplayBtn={false}
                            />
                          </TableCell>
                        </TableRow>
                      ) : (
                        exportMasterItemsData.map(
                          (row: exportDataMasterItemsDto, index: any) => {
                            return isMobile ? (
                              <>
                                <MobileCardView
                                  key={row.exportMasterID}
                                  row={row}
                                  handleExportItem={handleExportItem}
                                />
                              </>
                            ) : (
                              <TableRow hover key={row.exportMasterID} id="trExportData">
                                <TableCell style={{ minWidth: 100 }} id="tdExportName">
                                  <Link
                                    to={""}
                                    id={`libtnExport`}
                                    onClick={() => {
                                      handleExportItem(row?.exportMasterID);
                                    }}
                                    className="primary"
                                  >
                                    {row?.name}
                                  </Link>
                                </TableCell>
                                <TableCell style={{ minWidth: 140 }} id="tdExportDescription">
                                  {row?.description}
                                </TableCell>
                                <TableCell style={{ minWidth: 110 }} id="tdExportSourceName">
                                  {row?.exportSourceName}
                                </TableCell>
                                <TableCell style={{ minWidth: 60 }} id="tdExportUserName">
                                  {row?.insertUserName}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* )} */}
              </Paper>
            </Box>
          )}
        </>
      </div>
    </>
  );
};

// Mobile card view component
const MobileCardView: React.FC<{
  row: exportDataMasterItemsDto;
  handleExportItem: (id: number) => void;
}> = ({ row, handleExportItem }) => {
  return (
    <Paper
      elevation={3}
      sx={{ marginBottom: "16px", padding: "0" }}
      className={"Card_view"}
    >
      <Typography variant="body1" className="Field_info">
        <span className="field_label">NAME</span>
        <Link
          id="hdEdExportMasterID"
          to={""}
          onClick={() => {
            handleExportItem(row?.exportMasterID);
          }}
          className="primary"
        >
          {row?.name}
        </Link>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">DESCRIPTION</span>{" "}
        <span className="Field_value">{row.description}</span>
      </Typography>

      <Typography variant="body1" className="Field_info">
        <span className="field_label">SOURCE</span>{" "}
        <span className="Field_value">{row.exportSourceName}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">CREATED BY</span>{" "}
        <span className="Field_value">{row.insertUserName}</span>
      </Typography>
    </Paper>
  );
};

export default ExportData;
