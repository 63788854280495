import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { AddressItemDto } from "../../../models/address-item-dto";
import useAddressLogic from "../../lookup/address/address.logic";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CodeDialog from "../code-dialog/code-dialog";
import { CountryItemDto } from "../../../models/country-item-dto";
import { ZipCodeItemDto } from "../../../models/zip-code-item-dto";
import { useForm } from "react-hook-form";
import useZipCodeLogic from "../../lookup/zip-code/zip-code.logic";
import { MySettingItemDto } from "../../../models/long-form-item-dto";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
import { handleNumericValue } from "../../util/numeric-value";
import useTimezoneFilterLogic from "../../lookup/timezone/timezone-filter.logic";

interface DeliveryAddressProps {
  onDeliveryDataChange: (updatedData: any) => void;
  DefaultData: MySettingItemDto;
  DeliveryData: any;
  formErrors: any;
  componentCalledFrom: any;
  onZipCodeDataChange: (errors: any) => void;
  setFormErrors: (errors: any) => void;
  countryData: CountryItemDto[];
  errorCode: any;
  setErrorCode: (errors: any) => void;
  onRequestTimeChange: (errors: any) => void;
  deliveryZipCodeError: any;
  SetDeliveryZipeCodeError: (errors: any) => void;
  addressDataList?: any;
}

const DeliveryDetails: React.FC<DeliveryAddressProps> = ({
  onDeliveryDataChange,
  DefaultData,
  DeliveryData,
  formErrors,
  componentCalledFrom,
  onZipCodeDataChange,
  setFormErrors,
  countryData,
  errorCode,
  setErrorCode,
  deliveryZipCodeError,
  onRequestTimeChange,
  SetDeliveryZipeCodeError,
  addressDataList,
}) => {
  const [open, setOpen] = useState(false);
  const { error, address } = useAddressLogic();
  const { zipCodeError, zipCode } = useZipCodeLogic();
  const [addressData, setAddressData] = useState<AddressItemDto[]>([]);
  const [zipCodeData, setZipCodeData] = useState<ZipCodeItemDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [selectedAddress, setSelectedAddress] = useState<AddressItemDto | null>(
    null
  );
  const [selectedZipCode, setSelectedZipCode] = useState<string>(
    DefaultData?.deliveryPostalCode
  );
  const [isShowAddress3, setIsShowAddress3] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState(
    DefaultData?.deliveryCountryID
  );
  const userGroupGUID: any = localStorage.getItem("userGroupGUID");
  const [deliveryCity, setDeliveryCity] = useState("");
  const [deliveryTimezoneID, setDeliveryTimezoneID] = useState("");
  const { register } = useForm();
  const [deliveryStandardAbbrID, setdeliveryStandardAbbrID] = useState("");
  const [formData, setFormData] = useState({
    deliveryAddress1: "",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryCityName: "",
    deliveryPostalCode: "",
    deliveryCountryID: "",
    deliveryStateProvID: "",
    deliveryCompanyName: "",
    deliveryInstructions: "",
    deliveryAirportID: "",
    deliveryEmailAddress: "",
    addressCode: "",
    deliveryPhoneNumber: "",
    deliveryAttention: "",
    delKeep: false,
    deliveryFaxNumber: "",
    deliveryTimezoneID: deliveryTimezoneID,
    deliveryRequestTime: "",
    standardAbbr: "",
  });
  const errorControls = [
    {
      name: "deliveryCompanyName",
      value: "delCompanyName",
    },
    {
      name: "deliveryCityName",
      value: "delCityName",
    },
    {
      name: "deliveryPostalCode",
      value: "delPostalCode",
    },
    {
      name: "deliveryAirportID",
      value: "delAirportID",
    },
  ];

  useEffect(() => {
    getDefaultZipCode();
  }, []);
  useEffect(() => {
    getAddress(addressDataList);
  }, [addressDataList]);

  useEffect(() => {
    if (DefaultData) {
      setSelectedCountry(DefaultData.deliveryCountryID);
      setSelectedZipCode(DefaultData.deliveryPostalCode);
      setDeliveryCity(DefaultData?.deliveryCityName || "");
      setDeliveryTimezoneID(
        DefaultData?.deliveryTimezoneID ?? DefaultData?.timezoneID
      );
      setFormData({
        deliveryAddress1: DefaultData.deliveryAddress1 || "",
        deliveryAddress2: DefaultData.deliveryAddress2 || "",
        deliveryAddress3: DefaultData.deliveryAddress3 || "",
        deliveryCityName: DefaultData.deliveryCityName || "",
        deliveryPostalCode: DefaultData.deliveryPostalCode || "",
        deliveryCountryID: DefaultData.deliveryCountryID || "",
        deliveryStateProvID: DefaultData.deliveryStateProvID || "",
        deliveryCompanyName: DefaultData.deliveryCompanyName || "",
        deliveryInstructions:
          DeliveryData.deliveryInstructions || DefaultData.deliveryInstructions,
        deliveryAirportID: DefaultData.deliveryAirportID || "",
        deliveryEmailAddress: DefaultData.deliveryEmailAddress || "",
        addressCode: "",
        deliveryPhoneNumber: DefaultData.deliveryPhoneNumber || "",
        deliveryAttention: DefaultData.deliveryAttention || "",
        delKeep: false,
        deliveryFaxNumber: DefaultData.deliveryFaxNumber,
        deliveryTimezoneID: deliveryTimezoneID,
        deliveryRequestTime: DefaultData.deliveryRequestTime,
        standardAbbr: deliveryStandardAbbrID,
      });
    }
  }, [DefaultData]);

  useEffect(() => {
    onDeliveryDataChange({
      ...formData,
    });
  }, [formData]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      deliveryTimezoneID: deliveryTimezoneID,
      standardAbbr: deliveryStandardAbbrID,
    }));
    onDeliveryDataChange({
      ...formData,
      deliveryTimezoneID: deliveryTimezoneID,
      standardAbbr: deliveryStandardAbbrID,
    });
  }, [deliveryTimezoneID, deliveryStandardAbbrID]);
  const [firstDefaultInstructions, setFirstDefaultInstructions] = useState(
    DefaultData.deliveryInstructions
  );
  const [currentSelectedInstructions, setCurrentSelectedInstructions] =
    useState("");

  useEffect(() => {
    if (selectedAddress) {
      if (!firstDefaultInstructions && selectedAddress.deliveryInstructions) {
        setFirstDefaultInstructions(firstDefaultInstructions);
      } else {
        setCurrentSelectedInstructions(selectedAddress.deliveryInstructions);
      }
      const combinedInstructions =
        (DefaultData.deliveryInstructions
          ? DefaultData.deliveryInstructions
          : "") +
        (DefaultData.deliveryInstructions &&
        selectedAddress.deliveryInstructions
          ? ","
          : "") +
        (selectedAddress.deliveryInstructions
          ? selectedAddress.deliveryInstructions
          : "");
      const maxLength = 2000;
      const finalInstructions =
        combinedInstructions.length > maxLength
          ? combinedInstructions.slice(0, maxLength - 1)
          : combinedInstructions;
      setSelectedCountry(selectedAddress.countryID);
      setSelectedZipCode(selectedAddress.postalCode);
      let pickupReadyNow = DeliveryData?.pickupReadyNow;
      let deliveryRequestAsap = DeliveryData?.deliveryRequestAsap;
      if (
        selectedAddress.pickupReadyTime &&
        selectedAddress.pickupReadyTime != "1900-01-01T00:00:00" &&
        selectedAddress.pickupReadyDay
      ) {
        pickupReadyNow = false;
      }
      if (
        selectedAddress.deliveryRequestTime &&
        selectedAddress.deliveryRequestTime != "1900-01-01T00:00:00" &&
        selectedAddress.deliveryRequestDay
      ) {
        deliveryRequestAsap = false;
      }
      setFormData((prevLongFormData: any) => ({
        ...prevLongFormData,
        deliveryAddress1: selectedAddress.address1 || "",
        deliveryAddress2: selectedAddress.address2 || "",
        deliveryAddress3: selectedAddress.address3 || "",
        deliveryCityName: selectedAddress.cityName || "",
        deliveryPostalCode: selectedAddress.postalCode || "",
        deliveryCountryID: selectedAddress.countryID || "",
        deliveryStateProvID: selectedAddress.stateProvID || "",
        deliveryCompanyName: selectedAddress.companyName || "",
        deliveryInstructions: finalInstructions,
        deliveryAirportID: selectedAddress.airportID || "",
        deliveryEmailAddress: selectedAddress.emailAddress || "",
        addressCode: selectedAddress.addressCode || "",
        deliveryPhoneNumber: selectedAddress.phoneNumber || "",
        deliveryAttention: selectedAddress.attention || "",
        deliveryTimezoneID: selectedAddress.timezoneID || "",
        deliveryFaxNumber: "",
        delAddressGUID: selectedAddress.addressGUID,
        holdForPickup: selectedAddress.holdForPickup,
        pickupReadyDay: selectedAddress.pickupReadyDay,
        deliveryRequestDay: selectedAddress.deliveryRequestDay,
        deliveryRequestTime: selectedAddress.deliveryRequestTime,
        pickupReadyTime: selectedAddress.pickupReadyTime,
        pickupReadyNow: pickupReadyNow,
        deliveryRequestAsap: deliveryRequestAsap,
      }));
      onDeliveryDataChange({
        deliveryAddress1: selectedAddress.address1 || "",
        deliveryAddress2: selectedAddress.address2 || "",
        deliveryAddress3: selectedAddress.address3 || "",
        deliveryCityName: selectedAddress.cityName || "",
        deliveryPostalCode: selectedAddress.postalCode || "",
        deliveryCountryID: selectedAddress.countryID || "",
        deliveryStateProvID: selectedAddress.stateProvID || "",
        deliveryCompanyName: selectedAddress.companyName || "",
        deliveryInstructions: finalInstructions,
        deliveryAirportID: selectedAddress.airportID || "",
        deliveryEmailAddress: selectedAddress.emailAddress || "",
        addressCode: selectedAddress.addressCode || "",
        deliveryPhoneNumber: selectedAddress.phoneNumber || "",
        deliveryAttention: selectedAddress.attention || "",
        deliveryTimezoneID: selectedAddress.timezoneID || "",
        deliveryFaxNumber: "",
        delAddressGUID: selectedAddress.addressGUID,
        holdForPickup: selectedAddress.holdForPickup,
        pickupReadyDay: selectedAddress.pickupReadyDay,
        deliveryRequestDay: selectedAddress.deliveryRequestDay,
        deliveryRequestTime: selectedAddress.deliveryRequestTime,
        pickupReadyTime: selectedAddress.pickupReadyTime,
        pickupReadyNow: pickupReadyNow,
        deliveryRequestAsap: deliveryRequestAsap,
      });
      getZipCode();
    }
  }, [selectedAddress, firstDefaultInstructions, currentSelectedInstructions]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  interface MenuStyles {
    maxHeight: string;
  }

  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "230px", // Default max height for desktop
  });

  const handleCountryChange = (e: any) => {
    const selectedCountryValue = e.target.value;
    setSelectedCountry(selectedCountryValue);
    onDeliveryDataChange({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const getAddress = async (
    addressDataList = null
  ): Promise<AddressItemDto[]> => {
    try {
      setIsLoading(true);
      const param = {
        addressCode: formData.addressCode,
        companyName: "",
        cityName: "",
        stateProvID: "",
        countryID: "",
        postalCode: "",
        userGroupGUID: userGroupGUID,
      };
      let response: any;
      //To avoid multiple request on load
      if (addressDataList) {
        response = addressDataList;
      } else {
        response = await address(param);
      }
      if (response.data && response.data.data) {
        const res = response.data.data;
        if (!param.addressCode) {
          setAddressData(res);
        }
        if (param.addressCode && res.length > 0) {
          setDeliveryCity(res[0].cityName);
          onSelectAddress(res[0]);
          setFormData({
            ...formData,
            deliveryAddress1: res[0].address1 || "",
            deliveryAddress2: res[0].address2 || "",
            deliveryAddress3: res[0].address3 || "",
            deliveryCityName: res[0].cityName || "",
            deliveryPostalCode: res[0].postalCode || "",
            deliveryCountryID: res[0].countryID || "",
            deliveryStateProvID: res[0].stateProvID || "",
            deliveryCompanyName: res[0].companyName || "",
            deliveryInstructions:
              (DefaultData.deliveryInstructions
                ? DefaultData.deliveryInstructions
                : "") +
              (DefaultData.deliveryInstructions && res[0].deliveryInstructions
                ? ","
                : "") +
              (res[0].deliveryInstructions ? res[0].deliveryInstructions : ""),
            deliveryAirportID: res[0].airportID || "",
            deliveryEmailAddress: res[0].emailAddress || "",
            addressCode: res[0].addressCode || "",
            deliveryPhoneNumber: res[0].phoneNumber || "",
            deliveryAttention: res[0].attention || "",
            deliveryTimezoneID: res[0].timezoneID || "",
            deliveryFaxNumber: "",
            delKeep: false,
          });
        }
        return res; // Return the data to be used in handleAddressCodeKeyDown
      } else {
        setAddressData([]);
        return [];
      }
    } catch (err) {
      setIsLoading(false);
      setAddressData([]);
      return []; // Return an empty array in case of an error
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = (name: string) => {
    let updatedName = name;
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange.name === updatedName) {
      updatedName = errorChange.value;
      if (formErrors[updatedName]) {
        setFormErrors((prevErrors: any) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[updatedName];
          return updatedErrors;
        });
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setErrorCode(""); // Clear error if data is found
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    onDeliveryDataChange({
      ...formData,
      [name]: value,
    });
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange) {
      handleError(name);
    }
  };

  const handleCityChange = (event: any) => {
    setDeliveryCity(event.target.value);
    let name = event.target.name;
    onDeliveryDataChange({
      ...formData,
      [name]: event.target.value,
    });
    setFormData((prevLongFormData) => ({
      ...prevLongFormData,
      [name]: event.target.value,
    }));
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange) {
      handleError(name);
    }
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
    onDeliveryDataChange({
      ...formData,
      [name]: checked,
    });
  };

  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedZipCode(event.target.value);
    onDeliveryDataChange({
      ...formData,
      [event.target.name]: event.target.value,
    });
    let errorChange: any = errorControls.find(
      (ec) => ec.name === event.target.name
    );
    if (errorChange) {
      handleError(event.target.name);
    }
  };

  const getDefaultZipCode = async () => {
    try {
      if (selectedZipCode) {
        const param = {
          postalCode: selectedZipCode,
          countryID: selectedCountry,
        };
        const response = await zipCode(param);
        if (response.data && response.data.data) {
          const res = response.data.data;
          const timezone = res.find(
            (x: any) => x.cityName === DefaultData?.deliveryCityName
          );
          setdeliveryStandardAbbrID(timezone?.standardAbbr);
          onZipCodeDataChange(res);
          if (res.length > 0) {
            setZipCodeData(res);
          }
        }
      }
    } catch (err) {
      setZipCodeData([]);
    }
  };

  const getZipCode = async () => {
    try {
      setIsLoading(true);
      if (selectedZipCode) {
        const param = {
          postalCode: selectedZipCode,
          countryID: selectedCountry,
        };
        const response = await zipCode(param);
        if (response.data && response.data.data) {
          const res = response.data.data;
          onZipCodeDataChange(res);
          if (res.length > 0) {
            setZipCodeData(res);
            setSelectedCountry(res[0].countryID);
            const cityExists = res.some(
              (r: { cityName: string }) => r.cityName === deliveryCity
            );

            if (!cityExists) {
              setDeliveryCity(res[0].cityName);
            }

            setDeliveryTimezoneID(res[0].timezoneID);
            setdeliveryStandardAbbrID(res[0].standardAbbr);
            setFormData((prevLongFormData) => ({
              ...prevLongFormData,
              deliveryCityName:
                (!cityExists ? res[0].cityName : deliveryCity) || "",
              deliveryPostalCode: res[0].postalCode || "",
              deliveryCountryID: res[0].countryID || "",
              deliveryStateProvID: res[0].stateProvID || "",
              deliveryTimezoneID: res[0].timezoneID || "",
              deliveryAirportID: res[0].airportID,
              standardAbbr: res[0].standardAbbr,
            }));
            onDeliveryDataChange((prevLongFormData: any) => ({
              ...prevLongFormData,
              deliveryCityName:
                (!cityExists ? res[0].cityName : deliveryCity) || "",
              deliveryPostalCode: res[0].postalCode || "",
              deliveryCountryID: res[0].countryID || "",
              deliveryStateProvID: res[0].stateProvID || "",
              deliveryTimezoneID: res[0].timezoneID || "",
              deliveryAirportID: res[0].airportID,
              standardAbbr: res[0].standardAbbr,
            }));
            if (res[0].airportID) {
              let errorChange: any = errorControls.find(
                (ec) => ec.name === "deliveryAirportID"
              );
              if (errorChange) {
                handleError("deliveryAirportID");
              }
            }
            if (res[0].cityName) {
              let errorChange: any = errorControls.find(
                (ec) => ec.name === "deliveryCityName"
              );
              if (errorChange) {
                handleError("deliveryCityName");
              }
            }
            return res[0];
          }
        }
      }
      SetDeliveryZipeCodeError(
        "You have entered an invalid Delivery Zip Code. Please ensure you have entered the proper Zip Code. If you continue to experience issues, please contact MNX."
      );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setZipCodeData([]);
    }
  };

  const onSelectAddress = (address: any) => {
    setSelectedAddress(address);
    setSelectedCountry(address.countryID);
    setSelectedZipCode(address.postalCode);
    onRequestTimeChange(address);
    setDeliveryCity(address.cityName);
    if (address.companyName) {
      let errorChange: any = errorControls.find(
        (ec) => ec.name === "deliveryCompanyName"
      );
      if (errorChange) {
        handleError("deliveryCompanyName");
      }
    }
    if (address.postalCode) {
      let errorChange: any = errorControls.find(
        (ec) => ec.name === "deliveryPostalCode"
      );
      if (errorChange) {
        handleError("deliveryPostalCode");
      }
    }
    setZipCodeData([]);
  };

  const handleZipCodeKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      try {
        const res = await getZipCode(); // Wait for getZipCode to complete
        if (res) {
          const cityExists = res.some(
            (r: { cityName: string }) => r.cityName === deliveryCity
          );
          onDeliveryDataChange((prevLongFormData: any) => ({
            ...prevLongFormData,
            deliveryCityName: (!cityExists ? res.cityName : deliveryCity) || "",
            deliveryPostalCode: res.postalCode,
            deliveryCountryID: res.countryID,
            deliveryStateProvID: res.stateProvID,
            deliveryTimezoneID: res.timezoneID,
            deliveryAirportID: res.airportID,
            standardAbbr: res.standardAbbr,
          }));
          setFormData((prevLongFormData) => ({
            ...prevLongFormData,
            deliveryCityName: (!cityExists ? res.cityName : deliveryCity) || "",
            deliveryPostalCode: res.postalCode,
            deliveryCountryID: res.countryID,
            deliveryStateProvID: res.stateProvID,
            deliveryTimezoneID: res.timezoneID,
            deliveryAirportID: res.airportID,
            standardAbbr: res.standardAbbr,
          }));

          if (res.airportID) {
            let errorChange: any = errorControls.find(
              (ec) => ec.name === "deliveryAirportID"
            );
            if (errorChange) {
              handleError("deliveryAirportID");
            }
          }
          if (res.cityName) {
            let errorChange: any = errorControls.find(
              (ec) => ec.name === "deliveryCityName"
            );
            if (errorChange) {
              handleError("deliveryCityName");
            }
          }
        } else {
          setZipCodeData([]);
          setFormData((prevLongFormData) => ({
            ...prevLongFormData,
            deliveryCityName: "",
            deliveryAirportID: "",
          }));
          onDeliveryDataChange((prevLongFormData: any) => ({
            ...prevLongFormData,
            deliveryCityName: "",
            deliveryAirportID: "",
          }));
        }
      } catch (error) {}
    }
  };

  const handleAddressCodeKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" || event.key === "Tab") {
      const { addressCode } = formData;
      if (addressCode.trim().length > 0) {
        try {
          setErrorCode(""); // Clear error if data is found
          const response = await getAddress(); // Await getAddress properly
          if (response.length === 0) {
            setErrorCode("Invalid Address Code entered."); // Set error message
          } else {
            setErrorCode(""); // Clear error if data is found
          }
        } catch (error) {
          setErrorCode(""); // Set error message for unexpected errors
        }
      }
    }
  };

  const handleZipCodeFocusOut = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    try {
      const res = await getZipCode(); // Wait for getZipCode to complete
      if (res) {
        const cityExists = res.some(
          (r: { cityName: string }) => r.cityName === deliveryCity
        );
        onDeliveryDataChange((prevLongFormData: any) => ({
          ...prevLongFormData,
          deliveryCityName: (!cityExists ? res.cityName : deliveryCity) || "",
          deliveryPostalCode: res.postalCode,
          deliveryCountryID: res.countryID,
          deliveryStateProvID: res.stateProvID,
          deliveryTimezoneID: res.timezoneID,
          deliveryAirportID: res.airportID,
          standardAbbr: res.standardAbbr,
        }));
        setFormData((prevLongFormData) => ({
          ...prevLongFormData,
          deliveryCityName: (!cityExists ? res.cityName : deliveryCity) || "",
          deliveryPostalCode: res.postalCode,
          deliveryCountryID: res.countryID,
          deliveryStateProvID: res.stateProvID,
          deliveryTimezoneID: res.timezoneID,
          deliveryAirportID: res.airportID,
          standardAbbr: res.standardAbbr,
        }));
        if (res.airportID) {
          let errorChange: any = errorControls.find(
            (ec) => ec.name === "deliveryAirportID"
          );
          if (errorChange) {
            handleError("deliveryAirportID");
          }
        }
        if (res.cityName) {
          let errorChange: any = errorControls.find(
            (ec) => ec.name === "deliveryCityName"
          );
          if (errorChange) {
            handleError("deliveryCityName");
          }
        }
      } else {
        setZipCodeData([]);
        setFormData((prevLongFormData) => ({
          ...prevLongFormData,
          deliveryCityName: "",
          deliveryAirportID: "",
        }));
        onDeliveryDataChange((prevLongFormData: any) => ({
          ...prevLongFormData,
          deliveryCityName: "",
          deliveryAirportID: "",
        }));
        setFormData((prevLongFormData) => ({
          ...prevLongFormData,
          deliveryPostalCode: selectedZipCode,
        }));
        onDeliveryDataChange((prevLongFormData: any) => ({
          ...prevLongFormData,
          deliveryPostalCode: selectedZipCode,
        }));
      }
    } catch (error) {}
  };

  // const getConcatenatedAddresses = (data: any[]) => {
  //   return data.map((address) => ({
  //     ...address,
  //     displayName: `${address.companyName}${
  //       address.address1 ? `, ${address.address1}` : ""
  //     }${address.cityName ? `, ${address.cityName}` : ""}${
  //       address.countryID ? `, ${address.countryID}` : ""
  //     }`,
  //   }));
  // };

  const getConcatenatedAddresses = (data: any[]) => {
    return data.map((address) => {
      const parts = [
        address.companyName?.trim(),
        address.address1?.trim(),
        address.cityName?.trim(),
        address.countryID?.trim(),
      ].filter((part) => part && part.length > 0);

      const displayName = parts.join(", ");

      return {
        ...address,
        displayName,
      };
    });
  };

  // Custom filter options function to ensure unique results in the search dropdown
  const customFilterOptions = (options: any[], { inputValue }: any) => {
    const trimmedInputValue = inputValue.trim().toLowerCase();

    // Filter the options based on the inputValue
    const filteredOptions = options.filter((option) =>
      option.displayName.toLowerCase().includes(trimmedInputValue)
    );

    // Use a Set to ensure uniqueness in the displayed results
    const uniqueDisplayNames = new Set();
    return filteredOptions.filter((option) => {
      const isUnique = !uniqueDisplayNames.has(option.displayName);
      uniqueDisplayNames.add(option.displayName);
      return isUnique;
    });
  };

  // Handling input change in Autocomplete
  const handleInputChange1 = (event: React.ChangeEvent<{}>, value: string) => {
    return value.trim();
  };

  // Handling address selection
  const handleAddressChange = (
    event: React.ChangeEvent<{}>,
    selectedAddressValue: any
  ) => {
    setErrorCode(null);
    if (selectedAddressValue) {
      if (selectedAddressValue.postalCode) {
        let errorChange: any = errorControls.find(
          (ec) => ec.name === "deliveryPostalCode"
        );
        if (errorChange) {
          handleError("deliveryPostalCode");
        }
      }
      if (selectedAddressValue.companyName) {
        let errorChange: any = errorControls.find(
          (ec) => ec.name === "deliveryCompanyName"
        );
        if (errorChange) {
          handleError("deliveryCompanyName");
        }
      }
      onDeliveryDataChange(selectedAddressValue);
      setSelectedAddress(selectedAddressValue);
      setSelectedCountry(selectedAddressValue.countryID);
      setSelectedZipCode(selectedAddressValue.postalCode);
      onRequestTimeChange(selectedAddressValue);
      setDeliveryCity(selectedAddressValue.cityName);
      setZipCodeData([]);
    }
  };

  // Include duplicates in the options
  const optionsWithDuplicates = getConcatenatedAddresses(addressData);

  return (
    <Box className="d-flex flex-column w-100 border rounded detail-info-section">
      <Typography variant="h6" className="Sub-header">
        Delivery Details
      </Typography>
      <Box className="d-flex flex-column gap-12">
        <FormControl size="medium">
          <Autocomplete
            id="txtDeliveryAddressBook"
            freeSolo
            options={optionsWithDuplicates}
            getOptionLabel={(option) => option.displayName || ""}
            filterOptions={customFilterOptions}
            onInputChange={handleInputChange1}
            onChange={handleAddressChange}
            value={selectedAddress || null}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Address Book"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
            sx={{ width: isMobile ? "100%" : undefined }}
          />
        </FormControl>

        <Box className="d-flex gap-16">
          <TextField
            variant="outlined"
            label="Code"
            id="txtDeliveryAddressCode"
            className="w-100"
            {...register("addressCode")}
            name="addressCode"
            onChange={handleInputChange}
            onKeyDown={handleAddressCodeKeyDown}
            value={formData.addressCode}
            error={errorCode}
            inputProps={{ maxLength: 52 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickOpen}
                    id="btnDeliveryCodeDialog"
                  >
                    <MoreHorizIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <CodeDialog
            open={open}
            handleClose={handleClose}
            addressType="deliveryDetails"
            onSelectAddress={onSelectAddress}
          />

          <TextField
            variant="outlined"
            label="Company Name"
            id="txtDeliveryCompanyName"
            {...register("deliveryCompanyName")}
            className="w-100"
            name="deliveryCompanyName"
            value={formData.deliveryCompanyName}
            onChange={handleInputChange}
            error={!!formErrors.delCompanyName}
            inputProps={{ maxLength: 50 }}
            InputProps={{
              endAdornment: !!formErrors.delCompanyName && (
                <InputAdornment position="end" disablePointerEvents={true}>
                  <InfoRoundedIcon
                    style={{ color: red[500] }}
                    fontSize="small"
                  ></InfoRoundedIcon>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <TextField
          variant="outlined"
          label="Address Line 1"
          id="txtDeliveryAddress1"
          {...register("deliveryAddress1")}
          value={formData.deliveryAddress1}
          name="deliveryAddress1"
          onChange={handleInputChange}
          inputProps={{ maxLength: 50 }}
        />
        <Box className="d-flex align-center gap-8">
          <TextField
            variant="outlined"
            className={isShowAddress3 ? "w-100" : "width-90"}
            label="Address Line 2  (Optional)"
            {...register("deliveryAddress2")}
            id="txtDeliveryAddress2"
            name="deliveryAddress2"
            value={formData.deliveryAddress2}
            onChange={handleInputChange}
            inputProps={{ maxLength: 50 }}
          />
          {!isShowAddress3 && (
            <IconButton onClick={() => setIsShowAddress3(true)} id="btnLfdaAdd">
              <AddIcon className="Add-icon" />
            </IconButton>
          )}
        </Box>
        {isShowAddress3 && (
          <TextField
            variant="outlined"
            label="Address Line 3 (Optional)"
            name="deliveryAddress3"
            id="txtDeliveryAddress3"
            value={formData.deliveryAddress3}
            onChange={handleInputChange}
            inputProps={{ maxLength: 50 }}
          />
        )}

        <Box className="d-flex gap-16">
          <FormControl size="medium" className="w-100">
            <InputLabel id="demo-select-small-label">City</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="ddDeliveryCity"
              value={deliveryCity} // Ensure this matches your state variable
              label="City"
              {...register("deliveryCityName")}
              name="deliveryCityName"
              fullWidth
              onChange={handleCityChange}
              sx={{ width: isMobile ? "100%" : undefined }}
              error={!!formErrors.delCityName}
            >
              {zipCodeData.length > 0 &&
                zipCodeData.map((city: any) => (
                  <MenuItem key={city.cityName} value={city.cityName}>
                    {city.cityName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            label="State"
            id="txtDeliveryState"
            {...register("deliveryStateProvID")}
            name="deliveryStateProvID"
            className="w-100"
            value={formData.deliveryStateProvID}
            onChange={handleInputChange}
            inputProps={{ maxLength: 3 }}
          />
        </Box>
        <Box className="d-flex gap-16">
          <TextField
            variant="outlined"
            {...register("deliveryPostalCode")}
            name="deliveryPostalCode"
            id="txtDeliveryPostalCode"
            label="ZIP"
            className="w-100"
            onChange={handleZipCodeChange}
            onKeyDown={handleZipCodeKeyDown}
            onBlur={handleZipCodeFocusOut}
            value={selectedZipCode}
            error={deliveryZipCodeError || !!formErrors.puPostalCode}
            InputLabelProps={{ shrink: !!selectedZipCode }}
            InputProps={{
              endAdornment: !!formErrors.delPostalCode && (
                <InputAdornment position="end" disablePointerEvents={true}>
                  <InfoRoundedIcon
                    style={{ color: red[500] }}
                    fontSize="small"
                  ></InfoRoundedIcon>
                </InputAdornment>
              ),
            }}
          />
          {/* <Select variant="outlined" label="Country" className="w-100"></Select> */}
          <FormControl size="medium" className="w-100">
            <InputLabel id="demo-select-small-label">Country</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="ddDeliveryCountry"
              value={selectedCountry} // Ensure this matches your state variable
              label="Country"
              {...register("deliveryCountryID")}
              name="deliveryCountryID"
              fullWidth
              onChange={handleCountryChange}
              sx={{ width: isMobile ? "100%" : undefined }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },

                PaperProps: {
                  style: menuStyles,
                },
              }}
            >
              {countryData.length > 0 &&
                countryData.map((country: any) => (
                  <MenuItem key={country.countryID} value={country.countryID}>
                    {country.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box className="d-flex gap-16">
          <TextField
            variant="outlined"
            label="ATTN"
            id="txtDeliveryAttention"
            className="w-100"
            {...register("deliveryAttention")}
            name="deliveryAttention"
            value={formData.deliveryAttention}
            onChange={handleInputChange}
            inputProps={{ maxLength: 50 }}
          />
          {componentCalledFrom === "LongForm" ? (
            <TextField
              variant="outlined"
              label="Airport"
              id="txtDeliveryAirport"
              {...register("deliveryAirportID")}
              name="deliveryAirportID"
              value={formData.deliveryAirportID}
              onChange={handleInputChange}
              className="w-100"
              inputProps={{
                maxLength: 3,
                onKeyPress: (event) => {
                  const charCode = event.charCode;
                  // Allow only letters (A-Z, a-z)
                  if (!/^[a-zA-Z]+$/.test(String.fromCharCode(charCode))) {
                    event.preventDefault();
                  }
                },
                onPaste: (event: React.ClipboardEvent) => {
                  const paste = event.clipboardData.getData("text");
                  // Prevent pasting if the pasted content contains non-letter characters
                  if (!/^[a-zA-Z]+$/.test(paste)) {
                    event.preventDefault();
                  }
                },
              }}
              error={!!formErrors.delAirportID}
              InputProps={{
                endAdornment: !!formErrors.delAirportID && (
                  <InputAdornment position="end" disablePointerEvents={true}>
                    <InfoRoundedIcon
                      style={{ color: red[500] }}
                      fontSize="small"
                    ></InfoRoundedIcon>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <></>
          )}
        </Box>
        <TextField
          variant="outlined"
          label="Instructions"
          id="txtDeliveryInstructions"
          {...register("deliveryInstructions")}
          name="deliveryInstructions"
          inputProps={{ maxLength: 2000 }}
          placeholder="Enter any additional instructions"
          value={DeliveryData.deliveryInstructions}
          onChange={handleInputChange}
          multiline
          rows={4}
        />
      </Box>
      <Box className="d-flex flex-column mt-3">
        <Typography variant="h6" className="Sub-header">
          Delivery Contact
        </Typography>
        <Box className="d-flex gap-12">
          <TextField
            variant="outlined"
            label="Phone"
            id="txtDeliveryPhoneNumber"
            {...register("deliveryPhoneNumber")}
            value={formData.deliveryPhoneNumber}
            onChange={handleInputChange}
            onKeyDown={handleNumericValue}
            name="deliveryPhoneNumber"
            className="w-100"
            inputProps={{ maxLength: 25 }}
          />
          <TextField
            variant="outlined"
            label="Email Address"
            id="txtDeliveryEmail"
            className="w-100"
            {...register("deliveryEmailAddress")}
            name="deliveryEmailAddress"
            value={formData.deliveryEmailAddress}
            onChange={handleInputChange}
            inputProps={{ maxLength: 100 }}
          />
        </Box>
        {componentCalledFrom === "LongForm" ? (
          <FormControlLabel
            control={
              <Checkbox
                name="delKeep"
                checked={formData.delKeep}
                onChange={handleCheckboxChange}
                id="cbDeliverySaveToAdressBook"
              />
            }
            label="Save to address book"
            className="pt-8"
          />
        ) : (
          <Box className="d-flex gap-12">
            <TextField
              variant="outlined"
              label="Fax"
              {...register("deliveryFaxNumber")}
              value={formData.deliveryFaxNumber}
              onChange={handleInputChange}
              id="txtDeliveryFaxNumber"
              name="deliveryFaxNumber"
              className="w-100 mt-10"
              inputProps={{ maxLength: 25 }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DeliveryDetails;
