import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import useCommodityLogic from "../../lookup/commodity/commodity.logic";
import {
  ContentItemDto,
  MySettingItemDto,
  PackageTypeItemDto,
} from "../../../models/long-form-item-dto";
import usePackageTypeLogic from "../../lookup/package-type/package-type.logic";
import useContentLogic from "../../lookup/contents/contents.logic";
import PiecesDialog from "../pieces-dialog/pieces-dialog";

import { MAX_COMPANY_ID } from "../../../config/constants";
import { PieceItemDto } from "../../../models/piece-item-dto";
import useCurrencyLogic from "../../lookup/currency/currency.logic";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
import {
  handleDecimalValue,
  handleNumericValue,
} from "../../util/numeric-value";
import { Piece } from "../../../models/long-form-request-item-dto";
import { useDecodeJWT } from "../../hook/use-decodeJWT";
import { capitalizeFirstLetter } from "../../util/common";
import { createDeafaultPieces } from "../../util/long-form ";
import moment from "moment";

interface PackageInfoProps {
  DefaultData: MySettingItemDto;
  onContentChange: (content: ContentItemDto | null) => void;
  onPakageInfoDataChange: (updatedData: any) => void;
  formErrors: any;
  onPiecesDataChange: (updatedData: any) => void;
  setFormErrors: (errors: any) => void;
  setPiecesData: (errors: any) => void;
  piecesData: Piece[];
  selectedContent: any;
  longFormData?: any;
  setPackageTypeDependancyLongFornCustID?: any;
}

interface PackageInfoState {
  pieceCount: number;
  commodity: string;
  weight: any;
  weightUOM: string;
  length: any;
  width: any;
  height: any;
  dimWeight: any;
  outerPackage: string;
  innerPackage: string;
  content: string;
  quantity: any;
  value: any;
  pieceDetails: PieceItemDto[] | null;
  currencyID: string;
}

const PackageInfo: React.FC<PackageInfoProps> = ({
  DefaultData,
  onContentChange,
  onPakageInfoDataChange,
  formErrors,
  onPiecesDataChange,
  setFormErrors,
  setPiecesData,
  piecesData,
  selectedContent,
  longFormData,
  setPackageTypeDependancyLongFornCustID,
}) => {
  const { accountNumber } = longFormData;
  const [commodities, setCommodities] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { commodityError, commodity } = useCommodityLogic();
  const {
    packageTypeError,
    packageType,
    packageTypeDependancy,
    calculateDimWtAPI,
  } = usePackageTypeLogic();
  const { contentError, content } = useContentLogic();
  const [packageTypes, setPackageTypes] = useState<PackageTypeItemDto[]>([]);
  const [contents, setContents] = useState<ContentItemDto[]>([]);
  const [open, setOpen] = useState(false);
  const { currencyError, currency } = useCurrencyLogic();
  const [showAddEditBtn, setShowAddEditBtn] = useState(true);
  const [packageTypeDependancyCustID, setPackageTypeDependancyCustID] =
    useState<any>(null);
  const token = useDecodeJWT();

  const errorControls = [
    {
      name: "pieceCount",
      value: "pieces",
    },
    {
      name: "commodity",
      value: "commodity",
    },
    {
      name: "weight",
      value: "weight",
    },
  ];

  const [currencies, setCurrencies] = useState<
    {
      currencyID: string;
      shortName: string;
      name: string;
      description: string;
      symbolText: string;
      symbolImage: string;
    }[]
  >([]);

  const [packageData, setPackageData] = useState<PackageInfoState>({
    pieceCount: 1,
    commodity: "",
    weight: "",
    weightUOM: "",
    length: "",
    width: "",
    height: "",
    dimWeight: "",
    outerPackage: "",
    innerPackage: "",
    content: "",
    quantity: "",
    value: "",
    pieceDetails: null,
    currencyID: "",
  });
  const [totalPieces, setTotalPieces] = useState(0);

  const getDimWt = async (
    paramsData: any,
    updateItem: any,
    updateItemIndex: number,
    updateDirectState: boolean = false
  ) => {
    try {
      setLoading(true);
      const response = await calculateDimWtAPI(paramsData);

      if (response.data && response.data) {
        if (updateDirectState && updateItemIndex == 0) {
          setPackageData((prev) => ({
            ...prev,
            dimWeight: response.data.data,
          }));
        }

        return (updateItem[updateItemIndex].dimWeight = response.data.data);
      }
    } catch (err) {
    } finally {
      setLoading(false); // Ensure loading is always false
    }
  };

  useEffect(() => {
    let defaultPieceItem: PieceItemDto[] = [
      {
        contents: DefaultData.contents || "",
        reference: DefaultData.reference,
        weight: DefaultData.weight,
        // dimWeight: calculateDimWeight(
        //   "US",
        //   "US",
        //   DefaultData?.pieces || 1,
        //   DefaultData?.length || 0,
        //   DefaultData?.width || 0,
        //   DefaultData?.height || 0,
        //   "IN",
        //   DefaultData?.weightUOM
        // ),
        dimWeight: "",
        weightUOM: DefaultData.weightUOM,
        length: DefaultData.length,
        width: DefaultData.width,
        height: DefaultData.height,
        sizeUOM: DefaultData.sizeUOM,
        packagingType: "",
        declaredValue: DefaultData.declaredValue || 0.0,
        declaredValueCurrencyID: DefaultData.currencyID,
        bagGUID: "",
        bagGUID2: "",
        count: 1,
        insertUserID: parseInt(token?.UserId),
        oBagGUID: "",
        oBagGUID2: "",
        countryOfOrigin: "",
        hsCode: "",
        quantity: DefaultData.pieces,
        carrierWaybillNumber: "",
        temperatureType: "",
        packagingTypeGUID_Outer: "",
        packagingTypeGUID_Inner: "",
        isDangerousGoods: DefaultData.isDangerousGoods,
        isRadioactive: DefaultData.dgIsRadioactive,
        dgUNNumber: DefaultData.dgUNNumber,
        dgCommodityClass: DefaultData.dgCommodityClass,
        dgProperName: DefaultData.dgProperName,
        dgDryIceWeight: DefaultData.dgDryIceWeight,
        dgGelPackCount: undefined,
        dgPackingGroup: DefaultData.dgPackingGroup,
        dgQuantityAndPackType: DefaultData.dgQuantityAndPackType,
        dgPackingInstr: DefaultData.dgPackingInst,
        dgAuthorization: DefaultData.dgAuthorization,
        carrierToProvideDryIce: false,
        carrierToProvideGelPack: false,
        isDryIceRequire: false,
        isGelPacksRequire: false,
      },
    ];

    const puReadtDate = longFormData.puReadyNow
      ? moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
      : longFormData.puReadyTime
      ? moment(longFormData.puReadyTime).format("YYYY-MM-DDTHH:mm:ss.SSS")
      : moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
    const params = {
      puReadyDateTime: puReadtDate,
      serviceID: 0,
      origCountryID: DefaultData?.pickupCountryID,
      destCountryID: DefaultData?.deliveryCountryID,
      length: DefaultData?.length || 0,
      width: DefaultData?.width || 0,
      height: DefaultData?.height || 0,
      sizeUOM: DefaultData?.sizeUOM || "IN",
      weightUOM: DefaultData?.weightUOM,
      qunatity: DefaultData?.pieces || 1,
      customerID: packageTypeDependancyCustID?.apiCustomerID || 0,
    };
    getDimWt(params, defaultPieceItem, 0, true);

    const defaultPiece = createDeafaultPieces(
      DefaultData,
      token,
      defaultPieceItem
    );

    if (DefaultData) {
      setPackageData((prev) => ({
        ...prev,
        pieceCount: DefaultData.pieces ?? 1,
        commodity: DefaultData.commodity,
        weight: DefaultData.weight,
        weightUOM: DefaultData.weightUOM,
        length: DefaultData.length,
        width: DefaultData.width,
        height: DefaultData.height,

        outerPackage: "",
        innerPackage: "",
        content: DefaultData.contents || "",
        quantity: "",
        value: "",
        pieceDetails: defaultPiece,
        currencyID: DefaultData.currencyID,
      }));
      onPakageInfoDataChange(DefaultData);
      setPiecesData(defaultPiece);
    }
  }, [DefaultData]);

  useEffect(() => {
    if (selectedContent && piecesData) {
      setPiecesData((prevState: any) => {
        const updatedArray = [...prevState]; // Create a shallow copy of the array
        updatedArray[0] = {
          ...updatedArray[0], // Spread the existing object at index 0
          isDangerousGoods: selectedContent?.isDangerousGoods, // Update values
          isRadioactive: selectedContent?.isRadioactive,
        };

        return updatedArray; // Return the updated array
      });
    }
  }, [selectedContent]);

  const getCommodity = useCallback(async () => {
    try {
      setLoading(true);
      const response = await commodity();
      if (response.data && response.data.data) {
        setCommodities(response.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const param = { accountNumber };

        const response = await packageTypeDependancy(param);
        if (response.data && response.data.data) {
          // Handle the response data
          const allPackagingAvailable =
            response.data.data?.allPackagingAvailable;
          const customerID = allPackagingAvailable
            ? 0
            : response.data.data?.customerID;
          setPackageTypeDependancyCustID({
            customerID: customerID,
            apiCustomerID: response.data.data?.customerID,
          });
          setPackageTypeDependancyLongFornCustID(
            response.data.data?.customerID
          );
          getPackageType(customerID);
        }
      } catch (err) {
        // Handle errors
      } finally {
        setLoading(false);
      }
    };

    if (accountNumber) {
      // Ensure the effect runs only when accountNumber is valid
      fetchData();
    }
  }, [accountNumber]);

  const getPackageType = useCallback(async (customerID: number) => {
    try {
      setLoading(true);
      const param = {
        companyID: MAX_COMPANY_ID,
        showDeleted: false,
        customerID: customerID,
        includeBlank: true,
      };

      const response = await packageType(param);
      if (response.data && response.data.data) {
        setPackageTypes(response.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const getContents = useCallback(async () => {
    try {
      setLoading(true);
      const param = {
        companyID: MAX_COMPANY_ID,
        contentsReferenceGroupGUID: DefaultData.contentsReferenceGroupGUID,
      };

      const response = await content(param);
      if (response.data && response.data.data) {
        setContents(response.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCommodity();

    getContents();
  }, [getCommodity, getContents]);

  useEffect(() => {
    getCurrency();
  }, []);

  const getCurrency = async () => {
    try {
      const response = await currency();
      if (response.data && response.data.data) {
        setCurrencies(response.data.data);
      }
    } catch (err) {}
  };

  const handleError = (name: string) => {
    let updatedName = name;
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange.name === updatedName) {
      updatedName = errorChange.value;
      if (formErrors[updatedName]) {
        setFormErrors((prevErrors: any) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[updatedName];
          return updatedErrors;
        });
      }
    }
  };

  const handlePiecesError = (updatedName: string, index: number) => {
    // Constructing the dynamic error key
    const errorKey = `piecesList[${index}].${updatedName}`;

    // Example logic to check and delete error from formErrors
    if (formErrors[errorKey]) {
      setFormErrors((prevErrors: any) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[errorKey];
        return updatedErrors;
      });
    }
  };

  const handlePackageTypeChange = (event: SelectChangeEvent) => {
    const selectedPackagingTypeGUID = event.target.value;
    const selectedPackage = packageTypes.find(
      (pkg) => pkg.packagingTypeGUID === selectedPackagingTypeGUID
    );

    if (selectedPackage) {
      setPackageData((prev) => {
        const updatedPieceDetails = prev.pieceDetails
          ? [...prev.pieceDetails]
          : [];

        // Update the 0th index of pieceDetails
        updatedPieceDetails[0] = {
          ...updatedPieceDetails[0],
          packagingTypeGUID_Outer: selectedPackage.packagingTypeGUID,
          length: selectedPackage.length ? selectedPackage.length : null,
          width: selectedPackage.width ? selectedPackage.width : null,
          height: selectedPackage.height ? selectedPackage.height : null,
        };
        return {
          ...prev,
          outerPackage: selectedPackage.packagingTypeGUID,
          length: selectedPackage.length ? selectedPackage.length : "",
          width: selectedPackage.width ? selectedPackage.width : "",
          height: selectedPackage.height ? selectedPackage.height : "",
          pieceDetails: updatedPieceDetails,
        };
      });
      // Update pieces data state
      setPiecesData((prevData: any[]) => {
        // Ensure prevData is an array and has at least one item
        if (!Array.isArray(prevData) || prevData.length === 0) {
          return prevData; // Return unchanged data if it's invalid
        }

        // Create a copy of the previous data to avoid direct mutation
        const updatedPiecesData = [...prevData];

        // Check if the first item exists
        if (!updatedPiecesData[0]) {
          return updatedPiecesData; // Return unchanged data if the first item is undefined
        }

        // Update the first item in the piecesData array with new packaging type and dimensions
        updatedPiecesData[0] = {
          ...updatedPiecesData[0], // Preserve existing data
          packagingTypeGUID_Outer: selectedPackage.packagingTypeGUID,
          length: selectedPackage.length ?? null,
          width: selectedPackage.width ?? null,
          height: selectedPackage.height ?? null,
        };

        return updatedPiecesData;
      });
    }

    onPakageInfoDataChange(selectedPackage);
  };

  const handleInnerChange = (event: SelectChangeEvent) => {
    const selectedPackagingTypeGUID = event.target.value;
    const selectedPackage = packageTypes.find(
      (pkg) => pkg.packagingTypeGUID === selectedPackagingTypeGUID
    );

    if (selectedPackage) {
      setPackageData((prev) => {
        const updatedPieceDetails = prev.pieceDetails
          ? [...prev.pieceDetails]
          : [];

        // Update the 0th index of pieceDetails
        updatedPieceDetails[0] = {
          ...updatedPieceDetails[0],
          packagingTypeGUID_Inner: selectedPackage.packagingTypeGUID,
        };

        return {
          ...prev,
          innerPackage: selectedPackage.packagingTypeGUID,
          pieceDetails: updatedPieceDetails,
        };
      });

      setPiecesData((prevData: any[]) => {
        // Ensure prevData is an array and has at least one item
        if (!Array.isArray(prevData) || prevData.length === 0) {
          return prevData; // Return unchanged data if it's invalid
        }

        // Create a copy of the previous data to avoid direct mutation
        const updatedPiecesData = [...prevData];

        // Check if the first item exists
        if (!updatedPiecesData[0]) {
          return updatedPiecesData; // Return unchanged data if the first item is undefined
        }

        // Update the first item in the piecesData array with new packaging type GUID
        updatedPiecesData[0] = {
          ...updatedPiecesData[0],
          packagingTypeGUID_Inner: selectedPackage.packagingTypeGUID,
        };

        return updatedPiecesData;
      });

      onPakageInfoDataChange((prev: any) => {
        const updatedPieceDetails = prev.pieceDetails
          ? [...prev.pieceDetails]
          : [];

        // Update the 0th index of pieceDetails for onPakageInfoDataChange as well
        updatedPieceDetails[0] = {
          ...updatedPieceDetails[0],
          innerPackage: selectedPackage.packagingTypeGUID,
        };

        return {
          ...prev,
          innerPackage: selectedPackage.packagingTypeGUID,
          pieceDetails: updatedPieceDetails,
        };
      });
    }
  };

  const handleContentChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    const selectedContent = contents.find(
      (c) => c.properName === selectedValue
    );

    setPackageData((prev) => {
      // Create a copy of the pieceDetails array to avoid direct mutation
      const updatedPieceDetails = prev.pieceDetails
        ? [...prev.pieceDetails]
        : [];

      // Update the content at index 0 if pieceDetails exists and has at least one element
      if (updatedPieceDetails.length > 0) {
        updatedPieceDetails[0] = {
          ...updatedPieceDetails[0],
          contents: selectedContent ? selectedValue : "",
        };
      }

      return {
        ...prev,
        content: selectedContent ? selectedValue : "",
        pieceDetails: updatedPieceDetails,
      };
    });

    // Update piecesData state
    setPiecesData((prevData: any[]) => {
      // Ensure prevData is an array and has at least one item
      if (!Array.isArray(prevData) || prevData.length === 0) {
        return prevData; // Return unchanged data if it's invalid
      }

      // Create a copy of the previous data to avoid direct mutation
      const updatedPiecesData = [...prevData];

      // Check if the first item exists
      if (updatedPiecesData[0] === undefined) {
        return updatedPiecesData; // Return unchanged data if the first item is undefined
      }

      // Update the first item in the piecesData array with new content
      updatedPiecesData[0] = {
        ...updatedPiecesData[0], // Preserve existing data
        contents: selectedContent ? selectedValue : "", // Specific field update
      };

      return updatedPiecesData;
    });

    if (selectedContent) {
      onContentChange(selectedContent);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDimWtCalculation = () => {
    if (
      packageData.pieceCount !== undefined &&
      packageData.length !== undefined &&
      packageData.width !== undefined &&
      packageData.height !== undefined &&
      packageData.weightUOM !== undefined
    ) {
      const pieceCount = Number(packageData.pieceCount);
      const length = Number(packageData.length);
      const width = Number(packageData.width);
      const height = Number(packageData.height);
      const weightUOM = String(packageData.weightUOM);

      if (
        !isNaN(pieceCount) &&
        !isNaN(length) &&
        !isNaN(width) &&
        !isNaN(height) &&
        weightUOM
      ) {
        const params = {
          puReadyDateTime: longFormData.puReadyNow
            ? new Date()
            : longFormData.puReadyTime
            ? longFormData.puReadyTime
            : new Date(),
          serviceID: longFormData?.serviceID,
          origCountryID: longFormData?.pickupCountryID,
          destCountryID: longFormData?.deliveryCountryID,
          length: length || 0,
          width: width || 0,
          height: height || 0,
          sizeUOM: longFormData?.sizeUOM || "IN",
          weightUOM: weightUOM,
          qunatity: pieceCount || 1,
          customerID: longFormData.accountNumber || 0,
        };

        // getDimWt(params, packageData, 0, true);
        // const result = calculateDimWeight(
        //   "US",
        //   "US",
        //   pieceCount,
        //   length,
        //   width,
        //   height,
        //   "IN",
        //   weightUOM
        // );
        // setPackageData((prev) => ({ ...prev, dimWeight: result }));
      } else {
      }
    }
  };

  interface MenuStyles {
    maxHeight: string;
    maxWidth: string;
  }

  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
    maxWidth: "60%",
  });

  const isXs = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed

  useEffect(() => {
    if (isXs) {
      setMenuStyles((prevStyles) => ({
        ...prevStyles,
        maxWidth: "80%",
      }));
    } else {
      setMenuStyles((prevStyles) => ({
        ...prevStyles,
        maxWidth: "60%",
      }));
    }
  }, [isXs]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Ensure pieceCount is not less than 1
    const validatedValue =
      name === "pieceCount" && parseFloat(value) < 1 ? "1" : value;
    setPackageData((prev) => {
      // Create a copy of the pieceDetails array to avoid direct mutation
      const updatedPieceDetails = prev.pieceDetails
        ? [...prev.pieceDetails]
        : [];

      // Update the relevant field at index 0 if pieceDetails exists and has at least one element
      if (updatedPieceDetails.length > 0) {
        const updatedName =
          name === "value"
            ? "declaredValue"
            : name === "pieceCount"
            ? "quantity"
            : name;
        updatedPieceDetails[0] = {
          ...updatedPieceDetails[0],
          [updatedName]: validatedValue,
        };
      }

      return {
        ...prev,
        [name]: validatedValue,
        pieceDetails: updatedPieceDetails,
      };
    });
    if (name === "weight") {
      setFormErrors((prevErrors: any) => {
        const updatedErrors = { ...prevErrors };
        const errorKey = `piecesList[0].${name}`; // Construct the key dynamically
        delete updatedErrors[errorKey]; // Remove the error using the correct key
        return updatedErrors;
      });
    }
    // Determine the fields to be updated in piecesData
    const updatedFields =
      name === "value"
        ? { declaredValue: validatedValue }
        : name === "pieceCount"
        ? { quantity: validatedValue }
        : { [name]: validatedValue };

    // Update piecesData state
    setPiecesData((prevData: any[]) => {
      // Ensure prevData is an array and has at least one item
      if (!Array.isArray(prevData) || prevData.length === 0) {
        return prevData; // Return unchanged data if it's invalid
      }

      // Create a copy of the previous data to avoid direct mutation
      const updatedPiecesData = [...prevData];

      // Check if the first item exists
      if (!updatedPiecesData[0]) {
        return updatedPiecesData; // Return unchanged data if the first item is undefined
      }

      // Update the first item in the piecesData array with new data
      updatedPiecesData[0] = {
        ...updatedPiecesData[0], // Preserve existing data
        ...updatedFields, // Overwrite with new fields
      };
      // Calculate dimWeight
      const pieceCount = updatedPiecesData[0].quantity || 1;
      const length = updatedPiecesData[0].length || 0;
      const width = updatedPiecesData[0].width || 0;
      const height = updatedPiecesData[0].height || 0;
      const weightUOM = updatedPiecesData[0].weightUOM || "";

      // const dimWeight = calculateDimWeight(
      //   "US",
      //   "US",
      //   pieceCount,
      //   length,
      //   width,
      //   height,
      //   "IN",
      //   weightUOM
      // );
      // updatedPiecesData[0].dimWeight = dimWeight;

      const params = {
        puReadyDateTime: longFormData.puReadyNow
          ? new Date()
          : longFormData.puReadyTime
          ? longFormData.puReadyTime
          : new Date(),
        serviceID: longFormData?.serviceID,
        origCountryID: longFormData?.puCountryID || "",
        destCountryID: longFormData?.delCountryID || "",
        length: length || 0,
        width: width || 0,
        height: height || 0,
        sizeUOM: longFormData?.sizeUOM || "IN",
        weightUOM: weightUOM,
        qunatity: pieceCount || 1,
        customerID: packageTypeDependancyCustID?.apiCustomerID || 0,
      };

      getDimWt(params, updatedPiecesData, 0, true);

      return updatedPiecesData;
    });

    // Notify parent component of the change
    onPakageInfoDataChange({ [name]: validatedValue });

    // Handle any error controls
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange) {
      handleError(name);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "pieceCount" && (!value || parseFloat(value) < 1)) {
      setPackageData((prev) => ({ ...prev, [name]: 1 }));
      onPakageInfoDataChange({ [name]: 1 });
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    // Creating an HTMLInputElement-like event object
    const { name, value } = event.target;
    const syntheticEvent = {
      target: {
        name: name,
        value: value,
      },
    } as ChangeEvent<HTMLInputElement>;
    handleInputChange(syntheticEvent);
  };

  const handlePiecesSelectChange = (e: any, index: any) => {
    const { name, value } = e.target;

    const syntheticEvent = {
      target: {
        name: name,
        value: value,
      },
    } as ChangeEvent<HTMLInputElement>;
    handlePiecesInputChange(syntheticEvent, index);
  };

  const handlePiecesInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    if (name === "quantity" && parseFloat(value) < 1) {
      return;
    }
    if (name === "packagingTypeGUID_Outer") {
      const selectedPackage = packageTypes.find(
        (pkg) => pkg.packagingTypeGUID === value
      );
      const updatedPieceDetails = [...packageData.pieceDetails!];
      updatedPieceDetails[index].height = selectedPackage?.height;
      updatedPieceDetails[index].length = selectedPackage?.length;
      updatedPieceDetails[index].width = selectedPackage?.width;
      updatedPieceDetails[index].sizeUOM = selectedPackage?.sizeUOM;
      updatedPieceDetails[index] = {
        ...updatedPieceDetails[index],
        [name]: value,
      };

      onPiecesDataChange(updatedPieceDetails);
    }

    const updatedPieceDetails = [...packageData.pieceDetails!];
    updatedPieceDetails[index] = {
      ...updatedPieceDetails[index],
      [name]: value,
    };
    if (name === "declaredValue") {
      if (value === "") {
        updatedPieceDetails[index] = {
          ...updatedPieceDetails[index],
          [name]: 0,
        };
      }
    }

    // Calculate dimWeight if dimensions are available
    const pieceCount = updatedPieceDetails[index].quantity;
    const length = updatedPieceDetails[index].length || 0;
    const width = updatedPieceDetails[index].width || 0;
    const height = updatedPieceDetails[index].height || 0;
    const weightUOM = updatedPieceDetails[index].weightUOM || "";

    // const dimWeight = calculateDimWeight(
    //   "US",
    //   "US",
    //   pieceCount,
    //   length,
    //   width,
    //   height,
    //   "IN",
    //   weightUOM
    // );
    // updatedPieceDetails[index].dimWeight = dimWeight;

    const params = {
      puReadyDateTime: longFormData.puReadyNow
        ? new Date()
        : longFormData.puReadyTime
        ? longFormData.puReadyTime
        : new Date(),
      serviceID: longFormData?.serviceID,
      origCountryID: longFormData?.puCountryID || "",
      destCountryID: longFormData?.delCountryID || "",
      length: length || 0,
      width: width || 0,
      height: height || 0,
      sizeUOM: longFormData?.sizeUOM || "IN",
      weightUOM: weightUOM,
      qunatity: pieceCount || 1,
      customerID: packageTypeDependancyCustID?.apiCustomerID || 0,
    };
    getDimWt(params, updatedPieceDetails, index);

    setPackageData((prevState) => ({
      ...prevState,
      pieceDetails: updatedPieceDetails,
    }));
    onPieceChange(updatedPieceDetails);
    onPiecesDataChange(updatedPieceDetails);
    handlePiecesError(name, index);
    setPiecesData(updatedPieceDetails);
  };

  const handleBlurMultiple = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedPieceDetails = [...packageData.pieceDetails!];
    if (name === "quantity" && (!value || parseFloat(value) < 1)) {
      updatedPieceDetails[index][name] = 1;
      setPackageData((prevState) => ({
        ...prevState,
        pieceDetails: updatedPieceDetails,
      }));
      onPieceChange(updatedPieceDetails);
      onPiecesDataChange(updatedPieceDetails);
      setPiecesData(updatedPieceDetails);
    }
  };

  const onPieceChange = (pieces: PieceItemDto[]) => {
    if (pieces.length > 1) {
      setShowAddEditBtn(false);
    } else {
      setShowAddEditBtn(true);
    }
    setPackageData((prevState) => ({
      ...prevState,
      width: pieces[0].width,
      height: pieces[0].height,
      weightUOM: pieces[0].weightUOM || "",
      length: pieces[0].length,
      weight: pieces[0].weight,
      dimWeight: pieces[0].dimWeight,
      innerPackage: pieces[0].packagingTypeGUID_Inner,
      outerPackage: pieces[0].packagingTypeGUID_Outer,
      content: pieces[0].contents || "",
      currencyID: pieces[0].declaredValueCurrencyID,
      value: pieces[0].declaredValue,
      carrierToProvideDryIce: pieces[0].carrierToProvideDryIce,
      carrierToProvideGelPack: pieces[0].carrierToProvideGelPack,
      isDangerousGoods: pieces[0].isDangerousGoods,
      dgIsRadioactive: pieces[0].isRadioactive,
      pieceCount: pieces[0].quantity,
      pieceDetails: pieces,
    }));
    onPiecesDataChange(pieces);
    setTotalPieces(pieces.length);
    const name = "pieceCount";
    onPakageInfoDataChange({ [name]: pieces.length });
    setPiecesData(pieces);
  };

  useEffect(() => {
    handleDimWtCalculation();
  }, [packageData]);

  const measurementUnit = DefaultData?.sizeUOM?.toLowerCase() || "in";
  return (
    <Box className="d-flex flex-column mt-24 w-100 border rounded">
      <Box className="p16">
        <Typography variant="h6" className="Sub-header">
          Package Information
        </Typography>
        <Typography variant="body1" className="pb-8 piece-info">
          Piece 1
        </Typography>
        <Box className="d-flex gap-2 Divider pb-16">
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                type="text"
                variant="outlined"
                id="txtQuantity"
                label="Quantity"
                className="w-100"
                name="pieceCount"
                value={packageData.pieceCount}
                onChange={handleInputChange}
                onKeyDown={handleNumericValue}
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 1, maxLength: 4 }}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <FormControl className="w-100">
                <InputLabel>Commodity</InputLabel>
                <Select
                  variant="outlined"
                  defaultValue=""
                  className="w-100"
                  label="Commodity"
                  id="ddCommodity"
                  name="commodity"
                  onChange={handleSelectChange}
                  value={packageData.commodity}
                  error={!!formErrors.commodity}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },

                    PaperProps: {
                      style: menuStyles,
                    },
                  }}
                >
                  {commodities.length > 0 &&
                    commodities.map((item: any) => (
                      <MenuItem
                        key={item.commodityGuid}
                        value={item.commodityName}
                      >
                        {item.commodityName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                variant="outlined"
                label="Weight"
                className="w-100"
                name="weight"
                id="txtWeight"
                value={packageData.weight}
                onKeyDown={handleDecimalValue}
                onChange={handleInputChange}
                error={!!formErrors[`piecesList[0].weight`]}
                InputProps={{
                  endAdornment: !!formErrors.weight && (
                    <InputAdornment position="end" disablePointerEvents={true}>
                      <InfoRoundedIcon
                        style={{ color: red[500] }}
                        fontSize="small"
                      ></InfoRoundedIcon>
                    </InputAdornment>
                  ),
                  inputProps: {
                    maxLength: 4,
                  },
                }}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <FormControl className="w-100">
                <InputLabel>Unit</InputLabel>
                <Select
                  variant="outlined"
                  defaultValue="LB"
                  className="w-100"
                  label="Unit"
                  id="ddWeightUOM"
                  name="weightUOM"
                  onChange={handleSelectChange}
                  value={packageData.weightUOM}
                >
                  <MenuItem value="LB" id="liPiLb">
                    LB
                  </MenuItem>
                  <MenuItem value="KG" id="liPiKg">
                    KG
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Typography variant="body1" className="pt-16 piece-info pb-8">
          Dimensions
        </Typography>
        <Box className="d-flex gap-2 mt-2 Divider pb-16">
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                variant="outlined"
                label={`Length (${measurementUnit})`}
                className="w-100"
                name="length"
                id="txtLength"
                value={packageData.length?.toString()}
                onChange={handleInputChange}
                onKeyDown={handleNumericValue}
                inputProps={{ min: 0, maxLength: 4 }}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                variant="outlined"
                label={`Width (${measurementUnit})`}
                className="w-100"
                id="txtWidth"
                name="width"
                value={packageData.width}
                onKeyDown={handleNumericValue}
                inputProps={{ min: 0, maxLength: 4 }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                variant="outlined"
                label={`Height (${measurementUnit})`}
                className="w-100"
                name="height"
                id="txtHeight"
                value={packageData.height}
                onKeyDown={handleNumericValue}
                inputProps={{ min: 0, maxLength: 4 }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                variant="outlined"
                label="Dim. Weight"
                id="txtDimWeight"
                className="w-100"
                name="dimWeight"
                disabled
                value={packageData.dimWeight}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </Box>

        <Typography variant="body1" className="pt-16 piece-info pb-8">
          Contents & Value
        </Typography>
        <Box className="d-flex gap-2">
          <Grid container spacing={2}>
            <Grid item lg={4} sm={4} md={4} xs={6}>
              <FormControl className="w-100">
                <InputLabel>Packaging</InputLabel>
                <Select
                  variant="outlined"
                  defaultValue=""
                  className="w-100"
                  label="Packaging"
                  id="ddOuterPackage"
                  name="outerPackage"
                  value={packageData.outerPackage}
                  onChange={handlePackageTypeChange}
                  renderValue={(selected) => {
                    const selectedType = packageTypes.find(
                      (pkg) => pkg.packagingTypeGUID === selected
                    );
                    return selectedType ? selectedType.name : "";
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    PaperProps: {
                      style: menuStyles,
                    },
                  }}
                >
                  <MenuItem disabled className="dropdown-grid Table-head">
                    <TableHead>
                      <TableRow className="table-fixed">
                        <TableCell className="weight-500 font14 width104 thead-cell">
                          Name
                        </TableCell>
                        <TableCell className="weight-500 font14 width200 thead-cell">
                          Description
                        </TableCell>
                        <TableCell className="weight-500 font14 width180 thead-cell">
                          Dimensions
                        </TableCell>
                        <TableCell className="weight-500 font14 width94p thead-cell">
                          Wt
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </MenuItem>

                  {packageTypes.map((row: PackageTypeItemDto, index: any) => (
                    <MenuItem
                      className="dropdown-grid"
                      key={row.packagingTypeGUID}
                      value={row.packagingTypeGUID}
                    >
                      <TableBody className="table-fixed ptb-8">
                        <TableCell className="width104 Info-wrap">
                          {row.name}
                        </TableCell>
                        <TableCell className="width200 Info-wrap">
                          {row.description}
                        </TableCell>
                        <TableCell className="width180 Info-wrap">
                          {row.length && row.width && row.height
                            ? `${row.length} X ${row.width} X ${row.height} ${row.sizeUOM}`
                            : ""}
                        </TableCell>
                        <TableCell className="width94p Info-wrap">
                          {row.weight && row.weightUOM
                            ? `${row.weight} ${row.weightUOM}`
                            : ""}
                        </TableCell>
                      </TableBody>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} sm={4} md={4} xs={6}>
              <FormControl className="w-100">
                <InputLabel>Inner</InputLabel>
                <Select
                  variant="outlined"
                  defaultValue=""
                  className="w-100"
                  label="Inner"
                  name="innerPackage"
                  value={packageData.innerPackage}
                  id="ddInnerPackage"
                  onChange={handleInnerChange}
                  renderValue={(selected) => {
                    const selectedType = packageTypes.find(
                      (pkg) => pkg.packagingTypeGUID === selected
                    );
                    return selectedType ? selectedType.name : "";
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },

                    PaperProps: {
                      style: menuStyles,
                    },
                  }}
                >
                  <MenuItem disabled className="dropdown-grid Table-head">
                    <TableHead>
                      <TableRow className="table-fixed">
                        <TableCell className="weight-500 font14 width104 thead-cell">
                          Name
                        </TableCell>
                        <TableCell className="weight-500 font14 width200 thead-cell">
                          Description
                        </TableCell>
                        <TableCell className="weight-500 font14 width180 thead-cell">
                          Dimensions
                        </TableCell>
                        <TableCell className="weight-500 font14 width94p thead-cell">
                          Wt
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </MenuItem>

                  {packageTypes.map((row: PackageTypeItemDto, index: any) => (
                    <MenuItem
                      className="dropdown-grid"
                      key={index}
                      value={row.packagingTypeGUID}
                    >
                      <TableBody className="table-fixed ptb-8">
                        <TableCell className="width104 Info-wrap">
                          {row.name}
                        </TableCell>
                        <TableCell className="width200 Info-wrap">
                          {row.description}
                        </TableCell>
                        <TableCell className="width180 Info-wrap">
                          {row.length && row.width && row.height
                            ? `${row.length} X ${row.width} X ${row.height} ${row.sizeUOM}`
                            : ""}
                        </TableCell>
                        <TableCell className="width94p Info-wrap">
                          {row.weight && row.weightUOM
                            ? `${row.weight} ${row.weightUOM}`
                            : ""}
                        </TableCell>
                      </TableBody>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} sm={4} md={4} xs={12}>
              <FormControl className="w-100">
                <InputLabel>
                  {DefaultData.contentsLabel
                    ? capitalizeFirstLetter(DefaultData?.contentsLabel)
                    : "Contents"}
                </InputLabel>
                <Select
                  variant="outlined"
                  defaultValue=""
                  className="w-100"
                  label={
                    DefaultData.contentsLabel
                      ? capitalizeFirstLetter(DefaultData?.contentsLabel)
                      : "Contents"
                  }
                  name="content"
                  id="ddContent"
                  value={packageData.content || ""}
                  onChange={handleContentChange}
                  renderValue={(selected) => selected}
                  inputProps={{ maxLength: 100 }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },

                    PaperProps: {
                      style: menuStyles,
                    },
                  }}
                >
                  {contents.length > 0 && (
                    <MenuItem disabled className="dropdown-grid Table-head">
                      <TableHead>
                        <TableRow className="table-fixed">
                          <TableCell className="weight-500 font14 width94p thead-cell">
                            UN #
                          </TableCell>
                          <TableCell className="weight-500 font14 width230 thead-cell">
                            Proper Name
                          </TableCell>
                          <TableCell className="weight-500 font14 width110 thead-cell">
                            Class
                          </TableCell>
                          <TableCell className="weight-500 font14 width94p thead-cell">
                            DG
                          </TableCell>
                          <TableCell className="weight-500 font14 width94p thead-cell">
                            RA
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </MenuItem>
                  )}
                  {contents.length > 0 ? (
                    contents.map((row: ContentItemDto, index: any) => (
                      <MenuItem
                        className="dropdown-grid"
                        key={index}
                        value={row.properName}
                      >
                        <TableBody className="table-fixed ptb-8">
                          <TableCell className="width94p Info-wrap">
                            {row.unNumber}
                          </TableCell>
                          <TableCell className="width230 Info-wrap">
                            {row.properName}
                          </TableCell>
                          <TableCell className="width110 Info-wrap">
                            {row.commodityClass}
                          </TableCell>
                          <TableCell className="width94p Info-wrap">
                            {row.isDangerousGoods ? "True" : "False"}
                          </TableCell>
                          <TableCell className="width94p Info-wrap">
                            {row.isRadioactive ? "True" : "False"}
                          </TableCell>
                        </TableBody>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No options available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={8}>
              <Grid container spacing={1}>
                <Grid item lg={4} md={4} sm={6} xs={6}>
                  <FormControl className="w-100">
                    <InputLabel>Currency</InputLabel>
                    <Select
                      variant="outlined"
                      defaultValue=""
                      className="w-100"
                      id="ddCurrency"
                      label="Contents"
                      name="currencyID"
                      value={packageData.currencyID}
                      onChange={handleSelectChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },

                        PaperProps: {
                          style: menuStyles,
                        },
                      }}
                    >
                      {currencies.length > 0 &&
                        currencies.map((item) => (
                          <MenuItem
                            key={item.currencyID}
                            value={item.currencyID}
                          >
                            {item.shortName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={8} sm={6} md={8} xs={6}>
                  <TextField
                    variant="outlined"
                    label="Value"
                    className="w-100"
                    id="txtValue"
                    name="value"
                    value={packageData.value ? packageData.value : ""}
                    onChange={handleInputChange}
                    onKeyDown={handleDecimalValue}
                    inputProps={{ maxLength: 8 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={12}>
              {showAddEditBtn && (
                <Box
                  className="d-flex justify-content-end w-100 align-center"
                  sx={{ height: "56px" }}
                >
                  <Button
                    id="btnAddEdit"
                    variant="outlined"
                    className="rounded add-edit_btn width100px"
                    onClick={handleClickOpen}
                  >
                    ADD/EDIT PIECE
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <div className="bcg-grey">
        {/* dynamic package info */}
        {packageData.pieceDetails &&
          packageData.pieceDetails?.map(
            (pieceData, index) =>
              index > 0 && (
                <>
                  <div className="p16">
                    <Typography variant="body1" className="pb-8 piece-info">
                      Piece {1 + index}
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item lg={3} md={3} sm={3} xs={6}>
                        <TextField
                          type="text"
                          variant="outlined"
                          label="Quantity"
                          className="w-100"
                          name="quantity"
                          id={`txtQuantity`}
                          value={pieceData.quantity}
                          onChange={(e) => handlePiecesInputChange(e, index)}
                          onKeyDown={handleNumericValue}
                          onBlur={(e) => handleBlurMultiple(e, index)}
                          inputProps={{ min: 1, maxLength: 4 }}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} sm={3} xs={6}>
                        <FormControl className="w-100">
                          <InputLabel>Commodity</InputLabel>
                          <Select
                            variant="outlined"
                            defaultValue=""
                            value={pieceData.commodity}
                            className="w-100"
                            id={`ddCommodity`}
                            label="Commodity"
                            name="comodity"
                            onChange={(e) => handlePiecesSelectChange(e, index)}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },

                              PaperProps: {
                                style: menuStyles,
                              },
                            }}
                          >
                            {commodities?.length > 0 &&
                              commodities.map((item: any) => (
                                <MenuItem
                                  key={item.commodityGuid}
                                  value={item.commodityName}
                                >
                                  {item.commodityName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={3} md={3} sm={3} xs={6}>
                        <TextField
                          variant="outlined"
                          label="Weight"
                          className="w-100"
                          name="weight"
                          id={`txtWeight`}
                          value={pieceData.weight}
                          onChange={(e) => handlePiecesInputChange(e, index)}
                          error={!!formErrors[`piecesList[${index}].weight`]}
                          InputProps={{
                            endAdornment: !!formErrors[
                              `piecesList[${index}].weight`
                            ] && (
                              <InputAdornment
                                position="end"
                                disablePointerEvents={true}
                              >
                                <InfoRoundedIcon
                                  style={{ color: red[500] }}
                                  fontSize="small"
                                ></InfoRoundedIcon>
                              </InputAdornment>
                            ),
                            inputProps: {
                              maxLength: 4,
                            },
                          }}
                          onKeyDown={handleDecimalValue}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} sm={3} xs={6}>
                        <FormControl className="w-100">
                          <InputLabel>Unit</InputLabel>
                          <Select
                            variant="outlined"
                            id={`ddWeightUOM`}
                            className="w-100"
                            label="Unit"
                            name="weightUOM"
                            onChange={(e) => handlePiecesSelectChange(e, index)}
                            value={pieceData.weightUOM}
                          >
                            <MenuItem value="LB" id="liPiLb">
                              LB
                            </MenuItem>
                            <MenuItem value="KG" id="liPiLb">
                              KG
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Typography
                      variant="body1"
                      className="pt-16 piece-info pb-8"
                    >
                      Dimensions
                    </Typography>
                    <Box className="d-flex gap-2 mt-2 Divider pb-16">
                      <Grid container spacing={2}>
                        <Grid item lg={3} md={3} sm={3} xs={6}>
                          <TextField
                            variant="outlined"
                            label={`Length (${measurementUnit})`}
                            className="w-100"
                            id={`txtLength`}
                            name="length"
                            value={pieceData.length}
                            InputLabelProps={{ shrink: !!pieceData.length }}
                            onChange={(e) => handlePiecesInputChange(e, index)}
                            onKeyDown={handleNumericValue}
                            inputProps={{ min: 0, maxLength: 4 }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={6}>
                          <TextField
                            variant="outlined"
                            label={`Width (${measurementUnit})`}
                            className="w-100"
                            name="width"
                            id={`txtWidth`}
                            value={pieceData.width}
                            InputLabelProps={{ shrink: !!pieceData.width }}
                            onChange={(e) => handlePiecesInputChange(e, index)}
                            onKeyDown={handleNumericValue}
                            inputProps={{ min: 0, maxLength: 4 }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={6}>
                          <TextField
                            variant="outlined"
                            label={`Height (${measurementUnit})`}
                            className="w-100"
                            name="height"
                            id={`txtHeight`}
                            value={pieceData.height}
                            onChange={(e) => handlePiecesInputChange(e, index)}
                            InputLabelProps={{ shrink: !!pieceData.height }}
                            onKeyDown={handleNumericValue}
                            inputProps={{ min: 0, maxLength: 4 }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={6}>
                          <TextField
                            variant="outlined"
                            label="Dim. Weight"
                            className="w-100"
                            id={`txtDimWeight`}
                            name="dimWeight"
                            value={pieceData.dimWeight}
                            disabled
                            onChange={(e) => handlePiecesInputChange(e, index)}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Typography
                      variant="body1"
                      className="pt-16 piece-info pb-8"
                    >
                      Contents & Value
                    </Typography>
                    <Box className="d-flex gap-2">
                      <Grid container spacing={2}>
                        <Grid item lg={4} sm={4} md={4} xs={6}>
                          <FormControl className="w-100">
                            <InputLabel>Packaging</InputLabel>
                            <Select
                              variant="outlined"
                              defaultValue=""
                              className="w-100"
                              label="Packaging"
                              id={`ddOuterPackage`}
                              name="packagingTypeGUID_Outer"
                              value={pieceData.packagingTypeGUID_Outer}
                              onChange={(e) =>
                                handlePiecesSelectChange(e, index)
                              }
                              renderValue={(selected) => {
                                const selectedType = packageTypes.find(
                                  (pkg) => pkg.packagingTypeGUID === selected
                                );
                                return selectedType ? selectedType.name : "";
                              }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },

                                PaperProps: {
                                  style: menuStyles,
                                },
                              }}
                            >
                              <MenuItem
                                disabled
                                className="dropdown-grid Table-head"
                              >
                                <TableHead>
                                  <TableRow className="table-fixed">
                                    <TableCell className="weight-500 font14 width104 thead-cell">
                                      Name
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width200 thead-cell">
                                      Description
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width180 thead-cell">
                                      Dimensions
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width94p thead-cell">
                                      Wt
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width230 thead-cell">
                                      Packaging Type GUID
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                              </MenuItem>
                              {packageTypes.map(
                                (row: PackageTypeItemDto, index: any) => (
                                  <MenuItem
                                    className="dropdown-grid"
                                    key={row.packagingTypeGUID}
                                    value={row.packagingTypeGUID}
                                  >
                                    <TableBody className="table-fixed ptb-8">
                                      <TableCell className="width104 Info-wrap">
                                        {row.name}
                                      </TableCell>
                                      <TableCell className="width200 Info-wrap">
                                        {row.description}
                                      </TableCell>
                                      <TableCell className="width180 Info-wrap">
                                        {row.length && row.width && row.height
                                          ? `${row.length} X ${row.width} X ${row.height} ${row.sizeUOM}`
                                          : ""}
                                      </TableCell>
                                      <TableCell className="width94p Info-wrap">
                                        {row.weight && row.weightUOM
                                          ? `${row.weight} ${row.weightUOM}`
                                          : ""}
                                      </TableCell>
                                      <TableCell className="width230 Info-wrap">
                                        {row.packagingTypeGUID}
                                      </TableCell>
                                    </TableBody>
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item lg={4} sm={4} md={4} xs={6}>
                          <FormControl className="w-100">
                            <InputLabel>Inner</InputLabel>
                            <Select
                              variant="outlined"
                              defaultValue=""
                              className="w-100"
                              label="Inner"
                              id={`ddInnerPackage`}
                              name="packagingTypeGUID_Inner"
                              value={pieceData.packagingTypeGUID_Inner}
                              onChange={(e) =>
                                handlePiecesSelectChange(e, index)
                              }
                              renderValue={(selected) => {
                                const selectedType = packageTypes.find(
                                  (pkg) => pkg.packagingTypeGUID === selected
                                );
                                return selectedType ? selectedType.name : "";
                              }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },

                                PaperProps: {
                                  style: menuStyles,
                                },
                              }}
                            >
                              <MenuItem
                                disabled
                                className="dropdown-grid Table-head"
                              >
                                <TableHead>
                                  <TableRow className="table-fixed">
                                    <TableCell className="weight-500 font14 width104 thead-cell">
                                      Name
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width200 thead-cell">
                                      Description
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width180 thead-cell">
                                      Dimensions
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width94p thead-cell">
                                      Wt
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                              </MenuItem>
                              {packageTypes.map(
                                (row: PackageTypeItemDto, index: any) => (
                                  <MenuItem
                                    className="dropdown-grid"
                                    key={index}
                                    value={row.packagingTypeGUID}
                                  >
                                    <TableBody className="table-fixed ptb-8">
                                      <TableCell className="width104 Info-wrap">
                                        {row.name}
                                      </TableCell>
                                      <TableCell className="width200 Info-wrap">
                                        {row.description}
                                      </TableCell>
                                      <TableCell className="width180 Info-wrap">
                                        {row.length && row.width && row.height
                                          ? `${row.length} X ${row.width} X ${row.height} ${row.sizeUOM}`
                                          : ""}
                                      </TableCell>
                                      <TableCell className="width94p Info-wrap">
                                        {row.weight && row.weightUOM
                                          ? `${row.weight} ${row.weightUOM}`
                                          : ""}
                                      </TableCell>
                                    </TableBody>
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item lg={4} sm={4} md={4} xs={12}>
                          <FormControl className="w-100">
                            <InputLabel>Contents</InputLabel>
                            <Select
                              variant="outlined"
                              className="w-100"
                              label="Contents"
                              name="contents"
                              id="ddContent"
                              value={pieceData.contents}
                              onChange={(e) =>
                                handlePiecesSelectChange(e, index)
                              }
                              renderValue={(selected) => selected}
                              inputProps={{ maxLength: 100 }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "right",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "right",
                                },

                                PaperProps: {
                                  style: menuStyles,
                                },
                              }}
                            >
                              {contents.length > 0 && (
                                <MenuItem
                                  disabled
                                  className="dropdown-grid Table-head"
                                >
                                  <TableHead>
                                    <TableRow className="table-fixed">
                                      <TableCell className="weight-500 font14 width94p thead-cell">
                                        UN #
                                      </TableCell>
                                      <TableCell className="weight-500 font14 width230 thead-cell">
                                        Proper Name
                                      </TableCell>
                                      <TableCell className="weight-500 font14 width110 thead-cell">
                                        Class
                                      </TableCell>
                                      <TableCell className="weight-500 font14 width94p thead-cell">
                                        DG
                                      </TableCell>
                                      <TableCell className="weight-500 font14 width94p thead-cell">
                                        RA
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                </MenuItem>
                              )}
                              {contents.length > 0 ? (
                                contents.map(
                                  (row: ContentItemDto, index: any) => (
                                    <MenuItem
                                      className="dropdown-grid"
                                      key={index}
                                      value={row.properName}
                                    >
                                      <TableBody className="table-fixed ptb-8">
                                        <TableCell className="width94p Info-wrap">
                                          {row.unNumber}
                                        </TableCell>
                                        <TableCell className="width230 Info-wrap">
                                          {row.properName}
                                        </TableCell>
                                        <TableCell className="width110 Info-wrap">
                                          {row.commodityClass}
                                        </TableCell>
                                        <TableCell className="width94p Info-wrap">
                                          {row.isDangerousGoods
                                            ? "True"
                                            : "False"}
                                        </TableCell>
                                        <TableCell className="width94p Info-wrap">
                                          {row.isRadioactive ? "True" : "False"}
                                        </TableCell>
                                      </TableBody>
                                    </MenuItem>
                                  )
                                )
                              ) : (
                                <MenuItem disabled>
                                  No options available
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={8}>
                          <Grid container spacing={1}>
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                              <FormControl className="w-100">
                                <InputLabel>Currency</InputLabel>
                                <Select
                                  variant="outlined"
                                  defaultValue=""
                                  className="w-100"
                                  label="Contents"
                                  id={`ddCurrency`}
                                  name="declaredValueCurrencyID"
                                  value={pieceData.declaredValueCurrencyID}
                                  onChange={(e) =>
                                    handlePiecesSelectChange(e, index)
                                  }
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },

                                    PaperProps: {
                                      style: menuStyles,
                                    },
                                  }}
                                >
                                  {currencies.length > 0 &&
                                    currencies.map((item) => (
                                      <MenuItem
                                        key={item.currencyID}
                                        value={item.currencyID}
                                      >
                                        {item.shortName}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item lg={8} sm={6} md={8} xs={6}>
                              <TextField
                                variant="outlined"
                                id={`txtDeclaredValue`}
                                label="Value"
                                className="w-100"
                                name="declaredValue"
                                defaultValue={
                                  pieceData.declaredValue
                                    ? pieceData.declaredValue
                                    : ""
                                }
                                onKeyDown={handleDecimalValue}
                                inputProps={{ maxLength: 8 }}
                                onChange={(e) =>
                                  handlePiecesInputChange(e, index)
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item lg={8} md={8} sm={8} xs={12}>
                          {!showAddEditBtn &&
                            totalPieces &&
                            index === totalPieces - 1 && (
                              <Box
                                className="d-flex justify-content-end w-100"
                                sx={{ height: "56px" }}
                              >
                                <Button
                                  variant="outlined"
                                  className="width100px rounded add-edit_btn"
                                  onClick={handleClickOpen}
                                  id={`btnAddEdit`}
                                >
                                  ADD/EDIT PIECE
                                </Button>
                              </Box>
                            )}
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </>
              )
          )}
      </div>
      <PiecesDialog
        open={open}
        handleClose={handleClose}
        onPieceChange={onPieceChange}
        packageData={piecesData}
        longFormData={longFormData}
        accountNumber={accountNumber}
        packageTypeDependancyCustID={packageTypeDependancyCustID}
      />
    </Box>
  );
};

export default PackageInfo;
